import React, { memo, useRef } from "react";
import { cn } from "@shared/editor/styles/utils";
import { AutoCompleteInput } from "./AutoCompleteInput";
import { AutoCompleteProps } from "./types";

const AutoComplete = memo((props: AutoCompleteProps) => {
  const {
    containerProps = {},
    className,
    options,
    value,
    selected,
    onSelect,
    onClear,
    label,
    onChange,
    ...rest
  } = props;
  const { className: containerClassName, ...restContainerProps } =
    containerProps;
  const [inputFocused, setInputFocused] = React.useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> =
    React.useCallback(() => {
      setInputFocused(true);
      onSelect?.(undefined);
      onChange?.("");
    }, [onChange, onSelect]);

  const handleBlur: React.FocusEventHandler<HTMLInputElement> =
    React.useCallback(() => {
      setTimeout(() => setInputFocused(false), 100);
    }, []);

  return (
    <div
      className={cn("flex flex-col z-50", containerClassName)}
      {...restContainerProps}
    >
      <AutoCompleteInput
        label={label}
        value={value}
        onChange={onChange}
        focused={inputFocused}
        selected={selected}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClear={onClear}
        {...rest}
      />

      <div
        className={cn(
          "w-full flex flex-col max-h-96 overflow-y-auto rounded-md transform transition-all duration-300 ease-in-out",
          {
            "opacity-100 scale-100": inputFocused,
            "h-0 opacity-0 scale-95 pointer-events-none": !inputFocused,
            hidden: !!selected,
          }
        )}
      >
        {options.map((option, idx) => (
          <button
            key={idx}
            type="button"
            className="p-2 bg-white dark:bg-[#111319] group hover:brightness-125 cursor-pointer flex"
            onClick={() => {
              onSelect?.(option);
              onChange?.(option.label);
              inputRef.current?.blur();
            }}
          >
            <span className="text-sm">{option.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
});

AutoComplete.displayName = "AutoComplete";

export { AutoComplete };

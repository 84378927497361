import { DownloadIcon } from "outline-icons";
import React, { memo, useCallback, useState, forwardRef } from "react";
import { cn } from "@shared/editor/styles/utils";
import { DragAndDropZoneProps } from "./types";

const DragAndDropZone = memo(
  forwardRef<HTMLInputElement, DragAndDropZoneProps>((props, ref) => {
    const {
      label,
      description,
      onSelectFiles,
      inputProps = {},
      className,
      ...rest
    } = props;
    const dragCounter = React.useRef<number>(0);
    const [isDragging, setIsDragging] = useState(false);

    const handleSelectFiles: React.ChangeEventHandler<HTMLInputElement> =
      useCallback(
        (ev) => {
          const fileList = ev.target.files ?? [];
          const files = Array.from([...fileList]);
          onSelectFiles(files);
        },
        [onSelectFiles]
      );

    const handleDragEnter = useCallback((ev) => {
      ev.preventDefault();
      dragCounter.current += 1;
      if (dragCounter.current === 1) {
        setIsDragging(true);
      }
    }, []);

    const handleDragLeave = useCallback((ev) => {
      ev.preventDefault();
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    }, []);

    const handleDragOver = useCallback((ev: React.DragEvent) => {
      ev.preventDefault();
    }, []);

    const handleDrop = useCallback(
      (ev: React.DragEvent) => {
        ev.preventDefault();
        dragCounter.current = 0;
        setIsDragging(false);

        const fileList = ev.dataTransfer.files;
        const files = Array.from([...fileList]);
        onSelectFiles(files);
      },
      [onSelectFiles]
    );

    return (
      <div
        className={cn(
          "flex items-center justify-center w-full mt-4",
          className
        )}
        {...rest}
      >
        <label
          htmlFor="dropzone-file"
          className={cn(
            "flex flex-col items-center justify-center w-full h-48 border-2 border-dashed rounded-lg cursor-pointer bg-card border-border hover:border-text-tertiary z-20",
            { "bg-zinc-100 dark:bg-zinc-500": isDragging }
          )}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <DownloadIcon />
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              {label}
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {description}
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            onChange={handleSelectFiles}
            multiple
            ref={ref}
            {...inputProps}
          />
        </label>
      </div>
    );
  })
);

DragAndDropZone.displayName = "DragAndDropZone";

export { DragAndDropZone };

import React, { Fragment, memo } from "react";
import ReactDOM from "react-dom";

import { cn } from "@shared/editor/styles/utils";
import { DialogProps } from "./types";

const Dialog = memo((props: DialogProps) => {
  const {
    onClose,
    open,
    children,
    className,
    contentClassName = "",
    ...rest
  } = props;

  if (!open) {
    return <Fragment />;
  }

  return ReactDOM.createPortal(
    <div
      className={cn(
        "fixed top-0 bottom-0 left-0 right-0 bg-backdrop z-[1000] flex items-center justify-center",
        className
      )}
      {...rest}
    >
      <div
        className="fixed top-0 bottom-0 left-0 right-0 z-[2000] bg-transparent"
        onClick={onClose}
      />
      <div
        className={cn(
          "flex flex-col w-full max-w-4xl overflow-y-auto max-h-[80vh] gap-2 px-6 py-6 rounded-xl z-[3000]",
          contentClassName
        )}
      >
        {children}
      </div>
    </div>,
    document.getElementById("dialog")!
  );
});

Dialog.displayName = "Dialog";

export { Dialog };

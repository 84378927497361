function splitParagraphs(paragraph: string, limit: number): string[] {
  const result: string[] = [];
  let startIndex = 0;

  while (startIndex < paragraph.length) {
    let endIndex = Math.min(startIndex + limit, paragraph.length);

    if (endIndex < paragraph.length) {
      const lastDotIndex = paragraph.lastIndexOf(".", endIndex - 1);
      if (lastDotIndex >= startIndex) {
        endIndex = lastDotIndex + 1;
      } else {
        const lastSpaceIndex = paragraph.lastIndexOf(" ", endIndex - 1);
        if (lastSpaceIndex >= startIndex) {
          endIndex = lastSpaceIndex;
        }
      }
    }

    result.push(paragraph.slice(startIndex, endIndex).trim());

    startIndex = endIndex;
  }

  return result;
}

const httpErrorMessages: Record<string, string> = {
  400: "Algo deu errado com a sua solicitação. Por favor, revise e tente novamente.",
  401: "Você não está autorizado a acessar este recurso. Faça login e tente novamente.",
  403: "Você não tem permissão para acessar esta página.",
  404: "Página ou recurso não encontrado.",
  405: "O método utilizado não é permitido para este recurso.",
  406: "O recurso solicitado não está disponível no formato esperado.",
  408: "A solicitação demorou muito para ser concluída. Por favor, tente novamente.",
  409: "Conflito detectado. Verifique sua solicitação e tente novamente.",
  410: "Este recurso não está mais disponível.",
  413: "O arquivo ou dados enviados são muito grandes para processar.",
  415: "O formato do arquivo ou dos dados não é suportado.",
  422: "Os dados enviados contêm erros. Por favor, revise e envie novamente.",
  429: "Você fez muitas solicitações em um curto período. Por favor, tente novamente mais tarde.",
  500: "Ocorreu um erro interno no servidor. Por favor, tente novamente mais tarde.",
  501: "Este recurso ainda não está implementado.",
  502: "O servidor recebeu uma resposta inválida. Por favor, tente novamente mais tarde.",
  503: "O servidor está temporariamente indisponível. Tente novamente mais tarde.",
  504: "A solicitação demorou muito para ser respondida. Por favor, tente novamente.",
  505: "A versão do protocolo HTTP utilizada não é suportada.",
};

export { splitParagraphs, httpErrorMessages };

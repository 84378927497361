import { PlusIcon } from "outline-icons";
import React, { Fragment, useEffect, useState } from "react";
import Collection from "~/models/Collection";
import { AutoComplete } from "~/components/AutoComplete";
import Button from "~/components/Button";
import useStores from "~/hooks/useStores";
import { SelectFolderProps } from "./types";

const SelectFolder = (props: SelectFolderProps) => {
  const { onSelectFolder, selectedFolder, onClickCreateFolder, onSubmit } =
    props;

  const { collections } = useStores();
  const [search, setSearch] = useState<string>("");
  const [visibleFolders, setVisibleFolders] = useState<Collection[]>(
    collections.all
  );

  useEffect(() => {
    setVisibleFolders(
      collections.all.filter((p) =>
        p.name.toLowerCase().includes(search.toLowerCase())
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Fragment>
      <div className="flex flex-row items-center justify-between">
        <span className="font-bold text-lg">Importação de arquivos</span>
        <Button icon={<PlusIcon />} neutral onClick={onClickCreateFolder}>
          Nova Pasta
        </Button>
      </div>
      <p className="my-1">Seleciona a pasta para importar seus arquivos</p>
      <AutoComplete
        placeholder="Escolha uma pasta"
        value={search}
        onChange={(v) => setSearch(v)}
        options={visibleFolders.map((c) => ({
          value: c.id,
          label: c.name,
        }))}
        selected={selectedFolder}
        onClear={() => {
          onSelectFolder(undefined);
          setSearch("");
        }}
        onSelect={(option) => {
          onSelectFolder(option);
        }}
      />
      <div className="flex justify-end mt-4">
        <Button
          disabled={!selectedFolder}
          onClick={() => onSubmit(selectedFolder)}
        >
          Próxima etapa
        </Button>
      </div>
    </Fragment>
  );
};

export { SelectFolder };

import { action } from "mobx";
import type { WhichAiQuery, WhichAIResult } from "~/types";
import { client } from "~/utils/ApiClient";

export interface WhichAICreateDocumentProps {
  documentIds: string[];
  prompt: string;
  docProps?: Record<string, any | undefined>;
}

export interface WhichAICollectionQueryProps {
  query: string;
  collectionId?: string;
}

export interface WhichAIQueryProps {
  query: string;
  documentId: string;
}

export interface WhichAIAssistantQueryProps {
  query: string;
}

export interface WhichAICopilotQuery {
  cursor: number;
  documentId: string;
  collectionId?: string;
}

export interface WhichAIDeleteChat {
  documentId?: string;
}

export interface WhichAIUpdateChat {
  message?: string;
  hasFeedback?: boolean;
  id: string;
}

export default class WhichAIStore {
  @action
  query = async (payload: WhichAIQueryProps): Promise<WhichAiQuery> => {
    const { query, documentId } = payload;
    const res = await client.post("/whichai.document.query", {
      query,
      documentId,
    });

    return res;
  };

  @action
  assistantQuery = async (
    payload: WhichAIAssistantQueryProps
  ): Promise<WhichAIResult> => {
    const { query } = payload;
    const res = await client.post("/whichai.query", {
      query,
    });

    return res;
  };

  @action
  collectionQuery = async (
    payload: WhichAICollectionQueryProps
  ): Promise<WhichAIResult> => {
    const { query, collectionId } = payload;
    const res = await client.post("/whichai.collection.query", {
      query,
      collectionId,
    });

    return res;
  };

  @action
  createDocumentWithAi = async (
    payload: WhichAICreateDocumentProps
  ): Promise<{
    ok: boolean;
    response?: { data: { id: string } | null };
    message: string | null;
  }> => {
    const { documentIds, docProps, prompt } = payload;
    const res = await client.post(
      "/whichai.create",
      {
        documentIds,
        docProps,
        prompt,
      },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  copilotQuery = async (
    payload: WhichAICopilotQuery
  ): Promise<{
    ok: boolean;
    response?: {
      suggestion: string;
    };
    status: number;
  }> => {
    const { cursor, documentId, collectionId } = payload;
    const res = await client.post(
      "/whichai.copilot.query",
      { cursor, documentId, collectionId },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  deleteChat = async (
    payload: WhichAIDeleteChat
  ): Promise<{
    ok: boolean;
    response?: string;
    status: number;
  }> => {
    const { documentId } = payload;
    const res = await client.post(
      "/whichai.delete.chat",
      { documentId },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  resumeCollection = async (
    collectionId: string
  ): Promise<{
    ok: boolean;
    response?: string;
    status: number;
  }> => {
    const res = await client.post(
      "/whichai.collection.resume",
      { collectionId },
      {
        retry: false,
      }
    );
    return res;
  };

  @action
  updateChat = async (
    payload: WhichAIUpdateChat
  ): Promise<{
    ok: boolean;
    response?: string;
    status: number;
  }> => {
    const { id, hasFeedback, message } = payload;
    const res = await client.put(
      "/which.message",
      { id, hasFeedback, message },
      {
        retry: false,
      }
    );
    return res;
  };
}

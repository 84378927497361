import { observer } from "mobx-react";
import React, { Fragment, useCallback, useState } from "react";
import { AutoCompleteOption } from "~/components/AutoComplete/types";
import { Dialog } from "~/components/Dialog";
import useStores from "~/hooks/useStores";
import { SelectOrCreateFolder } from "./SelectOrCreateFolder";
import { UploadDocuments } from "./UploadDocuments";

function ImportDocumentsModalComponent() {
  const { dialogs } = useStores();
  const [step, setStep] = useState<number>(1);
  const [selectedFolder, setSelectedFolder] = useState<AutoCompleteOption>();

  const handleClose = useCallback(() => {
    dialogs.importDocumentOpen = false;
    setSelectedFolder(undefined);
    setStep(1);
  }, [dialogs]);

  const handleSelectFolder = useCallback((folder: AutoCompleteOption) => {
    setSelectedFolder(folder);
    setStep(2);
  }, []);

  if (!open) {
    return <Fragment />;
  }

  return (
    <Dialog
      open={dialogs.importDocumentOpen}
      onClose={handleClose}
      className="min-h-96"
      contentClassName="min-w-40 w-fit"
    >
      {step === 1 && (
        <SelectOrCreateFolder onSelectFolder={handleSelectFolder} />
      )}
      {step === 2 && (
        <UploadDocuments
          folder={selectedFolder!}
          onBack={() => setStep(1)}
          onClose={handleClose}
        />
      )}
    </Dialog>
  );
}

const ImportDocumentsModal = observer(ImportDocumentsModalComponent);

export { ImportDocumentsModal };

import { LazyMotion } from "framer-motion";
import { KBarProvider } from "kbar";
import mixpanel from "mixpanel-browser";
import { Provider } from "mobx-react";
import * as React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "react-router-dom";
import stores from "~/stores";
import Analytics from "~/components/Analytics";
import Dialogs from "~/components/Dialogs";
import ErrorBoundary from "~/components/ErrorBoundary";
import PageTheme from "~/components/PageTheme";
import ScrollToTop from "~/components/ScrollToTop";
import Theme from "~/components/Theme";
import Toasts from "~/components/Toasts";
import env from "~/env";
import Desktop from "./components/DesktopEventHandler";
import LazyPolyfill from "./components/LazyPolyfills";
import PageScroll from "./components/PageScroll";
import Routes from "./routes";
import { ImportDocumentsModal } from "./scenes/Collection/components/ImportDocumentsModal";
import history from "./utils/history";

mixpanel.init(env.MIX_PANEL_TOKEN, {
  track_pageview: true,
  persistence: "localStorage",
});

// Make sure to return the specific export containing the feature bundle.
const loadFeatures = () => import("./utils/motion").then((res) => res.default);

const commandBarOptions = {
  animations: {
    enterMs: 250,
    exitMs: 200,
  },
};

const App = () => (
  <React.StrictMode>
    <HelmetProvider>
      <Provider {...stores}>
        <Analytics>
          <Theme>
            <ErrorBoundary showTitle>
              <KBarProvider actions={[]} options={commandBarOptions}>
                <LazyPolyfill>
                  <LazyMotion features={loadFeatures}>
                    <Router history={history}>
                      <PageScroll>
                        <PageTheme />
                        <ScrollToTop>
                          <Routes />
                        </ScrollToTop>
                        <Toasts />
                        <ImportDocumentsModal />
                        <Dialogs />
                        <Desktop />
                      </PageScroll>
                    </Router>
                  </LazyMotion>
                </LazyPolyfill>
              </KBarProvider>
            </ErrorBoundary>
          </Theme>
        </Analytics>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

export { App };

import { BackIcon, CheckmarkIcon, TrashIcon, WarningIcon } from "outline-icons";
import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import colors from "tailwindcss/colors";
import { cn } from "@shared/editor/styles/utils";
import Button from "~/components/Button";
import { DragAndDropZone } from "~/components/DragAndDropZone";
import Spinner from "~/components/Spinner";
import Switch from "~/components/Switch";
import useStores from "~/hooks/useStores";
import { UploadDocumentsProps, UploadStatus } from "./types";

function getFileKeyName(file: File): string {
  return file.name.replace(/[^a-zA-Z0-9]/g, "");
}

function omit<T>(
  source: Record<string, any>,
  ...key: Extract<keyof T, string>[]
): T {
  return Object.entries(source).reduce((prev, [currentKey, currentValue]) => {
    if (key.includes(currentKey as any)) {
      return prev;
    } else {
      return { ...prev, [currentKey]: currentValue };
    }
  }, {} as T);
}

function UploadDocuments(props: UploadDocumentsProps) {
  const { folder, onBack, onClose } = props;
  const { documents } = useStores();
  const [statusMap, setStatusMap] = useState<Record<string, UploadStatus>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [files, setFiles] = useState<File[]>([]);
  const history = useHistory();
  const switchRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const completed: boolean = useMemo(() => {
    const values = Object.values(statusMap);
    return (
      values.length > 0 &&
      Object.values(statusMap).every((p) => p === "success")
    );
  }, [statusMap]);

  const handleRemoveFile = useCallback((file: File) => {
    const key = getFileKeyName(file);
    setFiles((prev) => prev.filter((f) => getFileKeyName(f) !== key));
    setErrors((prev) => ({ ...omit(prev, key) }));
    setStatusMap((prev) => ({ ...omit(prev, key) }));

    if (inputRef.current?.files) {
      const fileList = Array.from(inputRef.current.files);
      const newFileList = fileList.filter((f) => getFileKeyName(f) !== key);
      const container = new DataTransfer();
      newFileList.forEach((f) => container.items.add(f));
      inputRef.current.files = container.files;
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    await Promise.all(
      files
        .filter((f) => {
          const key = getFileKeyName(f);
          const status = statusMap[key];
          return !status || status === "error";
        })
        .map(async (file) => {
          const key = getFileKeyName(file);
          try {
            setStatusMap((prev) => ({ ...prev, [key]: "loading" }));
            await documents.import(file, null, folder.value, {
              publish: !switchRef.current?.checked,
            });
            setStatusMap((prev) => ({ ...prev, [key]: "success" }));
            setErrors((prev) => omit(prev, key));
          } catch (err) {
            const errMessage = err?.message ?? `${err}`;

            setErrors((prev) => ({
              ...prev,
              [key]: errMessage,
            }));
            setStatusMap((prev) => ({ ...prev, [key]: "error" }));

            window.console.log("Error to upload documents: ", err);
          }
        })
    );
  }, [documents, files, folder.value, statusMap]);

  const handleNavigateOnComplete = useCallback(() => {
    onClose();
    history.push(`/collection/${folder.value}`);
  }, [folder.value, history, onClose]);

  const handleCheckFile = useCallback((file: File) => {
    const isTooLarge = file.size > 1024 * 1024 * 20;
    const key = getFileKeyName(file);

    if (isTooLarge) {
      setErrors((previusErrors) => ({
        ...previusErrors,
        [key]: "Este arquivo é muito grande",
      }));
      setStatusMap((previusStatusMap) => ({
        ...previusStatusMap,
        [key]: "invalid",
      }));
    }
  }, []);

  const handleSelectFiles = useCallback(
    (inputFiles: File[]) => {
      inputFiles.forEach(handleCheckFile);

      setFiles((prev) =>
        prev.concat(
          inputFiles.filter((p) => !prev.find((f) => f.name === p.name))
        )
      );
    },
    [handleCheckFile]
  );

  const renderFeedback = useCallback(
    (status?: UploadStatus, error?: string) => {
      if (!status) {
        return <Fragment />;
      }

      if (status === "loading") {
        return (
          <div className="flex flex-row items-center gap-2">
            <span className="text-xs">Carregando</span>
          </div>
        );
      } else if (status === "success") {
        return (
          <div className="flex flex-row items-center gap-2 text-green-500 dark:text-green-300">
            <span className="text-sm font-medium">Carregado com sucesso</span>
          </div>
        );
      }

      return (
        <div className="flex flex-row items-center gap-2 text-red-500 dark:text-red-300">
          <span className="text-xs">{error}</span>
        </div>
      );
    },
    []
  );

  const renderActions = useCallback(
    (file: File, status: string) => {
      if (!status) {
        return (
          <button
            className="hover:bg-input p-2 rounded-md"
            onClick={() => handleRemoveFile(file)}
          >
            <TrashIcon color={colors.red[400]} />
          </button>
        );
      }

      if (status === "loading") {
        return <Spinner color={colors.blue[400]} />;
      } else if (status === "success") {
        return <CheckmarkIcon color={colors.green[400]} />;
      }

      return (
        <div className="flex flex-row gap-2 items-center">
          <WarningIcon color={colors.yellow[400]} />
          <button
            className="hover:bg-input p-2 rounded-md"
            onClick={() => handleRemoveFile(file)}
          >
            <TrashIcon color={colors.red[400]} />
          </button>
        </div>
      );
    },
    [handleRemoveFile]
  );

  return (
    <div className="bg-card rounded-lg shadow-lg border-border h-full flex flex-col gap-2 p-8">
      <div className="flex flex-row items-center justify-between">
        <div className="font-light text-lg flex flex-row items-center gap-1">
          <span className="hover:underline cursor-pointer" onClick={onBack}>
            Importação de arquivos
          </span>
          <span>{" > "}</span>
          <span className="text-text-secondary font-bold">{folder.label}</span>
        </div>
        <span className={cn({ hidden: completed })}>
          <Button icon={<BackIcon />} neutral onClick={onBack}>
            Voltar
          </Button>
        </span>
      </div>
      <DragAndDropZone
        label={
          <>
            <span className="font-semibold">Clique para realizar upload</span>{" "}
            ou arraste e solte
          </>
        }
        description="PDF, DOCX (MAX. 20mb*)"
        onSelectFiles={handleSelectFiles}
        inputProps={{
          accept:
            "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }}
        className={cn({
          hidden: completed,
        })}
        ref={inputRef}
      />
      <div
        className={cn(
          "flex flex-row items-center justify-center w-full bg-input p-4 rounded-md mt-4",
          {
            hidden: !completed,
          }
        )}
      >
        <span className="text-green-500 dark:text-green-300 font-medium text-xl">
          Todos arquivos foram importados com sucesso
        </span>
      </div>
      <div className="min-h-8 my-4 flex flex-col gap-1">
        {files.map((f, idx) => {
          const key = getFileKeyName(f);
          const status = statusMap[key];
          const error = errors[key];

          return (
            <div
              key={idx}
              className="flex flex-row items-center rounded-md px-2 gap-2"
            >
              <div className="w-full flex items-center justify-between">
                <span className="text-text-secondary text-sm italic">
                  {f.name} {"(" + Math.round(f.size / 1e6).toFixed(2) + "mb)"}
                </span>
              </div>
              <div
                className={cn(
                  "rounded-md w-full flex items-center justify-end gap-2",
                  { hidden: !status }
                )}
              >
                {renderFeedback(status, error)}
              </div>
              <div className="flex items-center justify-center">
                {renderActions(f, status)}
              </div>
            </div>
          );
        })}
      </div>
      <div className={cn({ hidden: completed })}>
        <Switch label="Publicar somente para você?" ref={switchRef} />
      </div>
      <div className="flex justify-end mt-4 gap-2">
        <span className={cn({ hidden: !completed })}>
          <Button type="button" neutral onClick={handleNavigateOnComplete}>
            Fechar
          </Button>
        </span>
        <span className={cn({ hidden: completed })}>
          <Button
            type="button"
            disabled={files.length === 0 || completed}
            onClick={handleSubmit}
            className={cn({ hidden: completed })}
          >
            Salvar
          </Button>
        </span>
      </div>
    </div>
  );
}

export { UploadDocuments };

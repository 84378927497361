import { CloseIcon } from "outline-icons";
import React, { forwardRef, useMemo } from "react";
import { cn } from "@shared/editor/styles/utils";
import { AutoCompleteInputProps } from "./types";

const AutoCompleteInput = forwardRef<HTMLInputElement, AutoCompleteInputProps>(
  (props, ref) => {
    const {
      name,
      value,
      className,
      selected,
      onClear,
      onSelect,
      placeholder,
      label,
      focused,
      onChange,
      ...rest
    } = props;

    const outFocus: boolean = useMemo(
      () => !focused && !value && !selected,
      [focused, value, selected]
    );

    return (
      <div
        className={cn(
          "relative flex items-center w-full py-3 px-4 bg-input rounded-lg pr-8 cursor-pointer border-[1px] border-border"
        )}
      >
        {label && (
          <label
            htmlFor={name ?? "autocomplete-input"}
            onClick={(e) => {
              e.preventDefault();
              document.getElementById(name ?? "autocomplete-input")?.focus();
            }}
            className={cn(
              "absolute -top-1/2 left-1 translate-y-0 text-blue-500 transition-all duration-300 ease-in-out z-10",
              {
                "!left-4 !top-1/2 !-translate-y-1/2 text-gray-500 !text-md":
                  outFocus,
                "text-xs": !outFocus,
              }
            )}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          name={name ?? "autocomplete-input"}
          value={value}
          onChange={(ev) => onChange?.(ev.target.value ?? "")}
          className={cn(
            "peer w-full bg-transparent !focus:outline-none !outline-none z-20",
            className
          )}
          placeholder={placeholder}
          {...rest}
        />

        {!!selected && (
          <button
            onClick={onClear}
            className="absolute top-1/2 -translate-y-1/2 right-2 hover:bg-gray-200 active:bg-gray-300 hover:dark:bg-zinc-900 active:dark:bg-zinc-950 rounded-md p-1"
          >
            <CloseIcon size={24} />
          </button>
        )}
      </div>
    );
  }
);

AutoCompleteInput.displayName = "AutoCompleteInput";

export { AutoCompleteInput };

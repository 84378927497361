import React, { useState } from "react";
import { AutoCompleteOption } from "~/components/AutoComplete/types";
import { SelectFolder, CreateFolder } from "./components";
import { SelectOrCreateFolderProps } from "./types";

function SelectOrCreateFolder(props: SelectOrCreateFolderProps) {
  const { onSelectFolder } = props;
  const [selectedOption, setSelectedOption] = useState<AutoCompleteOption>();
  const [createOption, setCreateOption] = useState<
    "select-folder" | "create-folder"
  >("select-folder");

  return (
    <div className="bg-card rounded-lg shadow-lg border-border h-full flex flex-col gap-2 p-8">
      {createOption === "select-folder" && (
        <SelectFolder
          onClickCreateFolder={() => setCreateOption("create-folder")}
          selectedFolder={selectedOption}
          onSelectFolder={(v) => setSelectedOption(v)}
          onSubmit={onSelectFolder}
        />
      )}
      {createOption === "create-folder" && (
        <CreateFolder
          onClickSelectFolder={() => setCreateOption("select-folder")}
          onSubmit={onSelectFolder}
        />
      )}
    </div>
  );
}

export { SelectOrCreateFolder };
